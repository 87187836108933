import * as requestFromServer from "./basePageCrud"
import { basePageSlice, callTypes } from "./basePageSlice"
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"
import { storage } from "../../../firebase"

const { actions } = basePageSlice

export const fetchPeople = customerId => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!customerId || customerId === null) {
		dispatch(actions.peopleFetched([]))
	}

	return requestFromServer
		.getPeople(customerId)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}
			const people = convertCollectionSnapshotToMap(response)

			people && dispatch(actions.peopleFetched(people))
			dispatch(fetchPeopleWithImages({ customerId, people }))
		})
		.catch(error => {
			console.log("Fetch people: ", error)
			error.clientMessage = "Can't fetch people"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAssets = customerId => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!customerId || customerId === null) dispatch(actions.assetsFetched([]))

	return requestFromServer
		.getAssets(customerId)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			const assets = convertCollectionSnapshotToMap(response)
			console.log("🚀  assets:", assets)

			assets && dispatch(actions.assetsFetched(assets))
			// dispatch(fetchPeopleWithImages({ customerId, people }))
		})
		.catch(error => {
			console.log("Fetch people: ", error)
			error.clientMessage = "Can't fetch people"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchGlobalOptions = customerId => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!customerId || customerId === null) dispatch(actions.peopleFetched([]))

	return requestFromServer
		.getGlobalOptions(customerId)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			dispatch(actions.globalOptionsFetched(response))
		})
		.catch(error => {
			console.log("Fetch global options: ", error)
			error.clientMessage = "Can't fetch global options"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const setRolesAndAssetTypes = queryParams => dispatch => {
	if (!queryParams.customer) {
		// dispatch(actions.rolesFetched([]))
		dispatch(actions.assetTypesFetched([]))
		return
	}

	// let roles = []
	// queryParams.roles && roles.push(queryParams.roles)
	// queryParams.customer.roles && roles.push(queryParams.customer.roles)
	// dispatch(actions.rolesFetched(types))

	let types = []
	if (queryParams.types) types = [...types, ...queryParams.types]
	if (queryParams.customer.assetTypes) types = [...types, ...queryParams.customer.assetTypes]
	dispatch(actions.assetTypesFetched(types))

	return
}

export const setTagSeenTimeout = queryParams => dispatch => {
	const { selectedSite, globalOptions } = queryParams
	const tagSeenTimeout =
		selectedSite?.options?.tagSeenTimeout || globalOptions?.map?.tagSeenTimeout || 600

	dispatch(actions.setTagSeenTimeout(tagSeenTimeout))
	return
}

const fetchPeopleWithImages = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) dispatch(actions.peopleFetched([]))

	return requestFromServer
		.getPeopleWithImages(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			response && dispatch(actions.peopleFetched(response))
		})
		.catch(error => {
			console.log("Fetch people with images: ", error)
			error.clientMessage = "Can't fetch people with images"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchFloorPlans = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams || queryParams === null || !queryParams?.siteId) {
		dispatch(actions.floorPlansFetched([]))
		return
	}

	return requestFromServer
		.getFloorPlans(queryParams)
		.then(async response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const data = convertCollectionSnapshotToMap(response)
			const floorPlans = await Promise.all(
				data.map(async floor => {
					if (floor.imagePath) {
						const url = await storage
							.ref(floor.imagePath)
							.getDownloadURL()
							.catch(error => {
								// Handle error
								console.error(error)
							})
						return { ...floor, imageUrl: url }
					}
					return floor
				})
			)

			const floorPlansOrdered = floorPlans.sort((a, b) => {
				if (a.level === b.level) {
					return 0
				} else if (a.level === 0) {
					return -1
				} else if (b.level === 0) {
					return 1
				} else if (a.level < 0 && b.level < 0) {
					return b.level - a.level // Negative levels: higher negative first
				} else if (a.level < 0) {
					return 1 // Negative after positive
				} else if (b.level < 0) {
					return -1 // Positive before negative
				} else {
					return a.level - b.level // Positive levels: lower first
				}
			})

			dispatch(actions.floorPlansFetched(floorPlansOrdered))
			return floorPlansOrdered
		})
		.catch(error => {
			console.log("Fetch floor plans: ", error)
			error.clientMessage = "Can't find floorplans"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// createNewFloorplan ManageSite
export const createNewFloorplan = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createFloorplan(queryParams)
		.then(response => response.json())
		.then(response => {
			// console.log("🚀  response?.floorplans?.[0]", response?.floorplans?.[0])
			// console.log("🚀  queryParams", queryParams);

			if (response?.floorplans?.[0]?.floorplanId) {
				const floorId = response?.floorplans?.[0]?.floorplanId

				const responseObject = {
					actionFinished: 100,
					id: floorId
				}

				dispatch(actions.floorplanCreated(response?.floorplans?.[0]))
				return responseObject
			} else {
				const responseObject = {
					actionFinished: 0,
					id: null
				}
				return responseObject
			}
		})
		.catch(error => {
			console.log("Create floorplan: ", error)
			error.clientMessage = "Can't create floorplan"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error creating floorplan`
			})
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const updateFloorPlanAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateFloorPan(queryParams)
		.then(response => response.json())
		.then(response => {
			if (!response) return

			// If POIS on that floor, delete them.
			if (queryParams.POISToDeleteArray || queryParams.POISToDeleteArray.length !== 0) {
				requestFromServer.deletePointsOfInterestAfterEditFloorplan({
					floorPlanId: response.floorplans?.[0].floorplanId,
					siteId: queryParams.siteId,
					POISToDeleteArray: queryParams.POISToDeleteArray
				})
			}

			if (response?.floorplans?.[0]?.floorplanId) {
				dispatch(actions.floorplanUpdated(response?.floorplans?.[0]))

				const responseObject = {
					actionFinished: 100,
					id: response?.floorplans?.[0]?.floorplanId
				}
				return responseObject
			} else {
				const responseObject = {
					actionFinished: 100,
					id: null
				}
				return responseObject
			}
		})

		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Floorplan"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteFloorPlanAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteFloorPlan(queryParams)
		.then(res => {
			queryParams.floorplansToDelete.forEach(val => {
				dispatch(actions.deletedFloorplan(val.id))
			})

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Floorplans successfully deleted.`
			})
		})

		.catch(error => {
			error.clientMessage = "Can't delete floorplans"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchAreas = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.areasFetched([]))
	}

	return requestFromServer
		.getAreas(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const areas = convertCollectionSnapshotToMap(response)

			dispatch(actions.areasFetched(areas))
		})
		.catch(error => {
			console.log("Fetch zones: ", error)
			error.clientMessage = "Can't find zones"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchDepartments = customerId => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!customerId) {
		dispatch(actions.departmentFetched({ entities: [] }))
	}

	return requestFromServer
		.getDepartments(customerId)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const departments = response.map(val => convertCollectionSnapshotToMap(val))?.flat() || []
			//const departments = convertCollectionSnapshotToMap(response)

			dispatch(actions.departmentFetched(departments[0]))
		})
		.catch(error => {
			console.log("Fetch departments: ", error)
			error.clientMessage = "Can't find departments"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchResources = queryparams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryparams) {
		dispatch(actions.resourcesFetched({ entities: [] }))
	}

	return requestFromServer
		.getResources(queryparams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const resources = convertCollectionSnapshotToMap(response)

			resources && dispatch(actions.resourcesFetched({ entities: resources }))
		})
		.catch(error => {
			console.log("fetch zones ", error)
			error.clientMessage = "Can't find zones"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchPointsOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.pointsOfInterestFetched({ entities: [] }))
	}

	return requestFromServer
		.getPointsOfInterest(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const pointsOfInterest = convertCollectionSnapshotToMap(response)

			dispatch(actions.pointsOfInterestFetched({ entities: pointsOfInterest }))
		})
		.catch(error => {
			console.log("fetch points of interest ", error)
			error.clientMessage = "Can't find points of interest"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
