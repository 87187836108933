import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap"
import PopoverStickOnHover from "../PopoverStickOnHover"
import { Button } from "@material-ui/core"
import DirectionsIcon from "@material-ui/icons/Directions"
import defaultPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/default point.png"
import coffeePoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/Coffee.png"
import extinguisherPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/extinguisher.png"
import foodPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/food.png"
import printerPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/printer.png"
import waterPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/water.png"
import WCPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/wc.png"
import cameraPoi from "../../../../assets/dashboard_markers/newMarkers/points-of-interest/camera.png"
import { IoLocationSharp } from "react-icons/io5"
import { percentageToSize, pixelToSize } from "./ActionHelpers"

export function addPOISHasLayers(params) {
	const { map, pointsOfInterest, showLayersBesidesTags, POISSize } = params

	const geoJsonArrayPOIS = pointsOfInterest
		? pointsOfInterest.map(d => {
				return {
					type: "Feature",
					properties: {
						...d
					},
					geometry: {
						type: "Point",
						coordinates: [d?.coords.longitude, d?.coords.latitude, 0.0]
					}
				}
		  })
		: []

	geoJsonArrayPOIS &&
		geoJsonArrayPOIS.forEach(feature => {
			const poi = feature.properties

			// Reset iconToMarker and iconVisible for each feature
			let iconToMarker = defaultPoi
			let imageId = "default-poi"

			// TODO: acrescentar aqui a image da camara.
			// ━━━ Change POI images for each type
			if (poi?.type === "Default") {
				iconToMarker = defaultPoi
				imageId = "default-poi"
			} else if (poi?.type === "Coffee machine") {
				iconToMarker = coffeePoi
				imageId = "coffee-poi"
			} else if (poi?.type === "Fire extinguisher") {
				iconToMarker = extinguisherPoi
				imageId = "extinguisher-poi"
			} else if (poi?.type === "Dining area") {
				iconToMarker = foodPoi
				imageId = "food-poi"
			} else if (poi?.type === "Printer") {
				iconToMarker = printerPoi
				imageId = "printer-poi"
			} else if (poi?.type === "Water dispenser") {
				iconToMarker = waterPoi
				imageId = "water-poi"
			} else if (poi?.type === "WC") {
				iconToMarker = WCPoi
				imageId = "wc-poi"
			} else if (poi?.type === "Camera") {
				iconToMarker = cameraPoi
				imageId = "camera-poi"
			}

			map.loadImage(iconToMarker, (error, img) => {
				// console.log("🚀  iconToMarker:", iconToMarker)
				// console.log("🚀  img:", img)
				if (error) {
					// console.log(`Error loading image ->`, error)
				}
				if (!img || error) return

				// If map already has the image we need to remove so there aren't any re-renders
				const mapHasImage = map.hasImage(imageId)
				// ↓ So don't get error of loading image with same sourceId
				if (mapHasImage) {
					map.removeImage(imageId)
				}

				// add icon image
				map.addImage(imageId, img)
			})

			// Set the iconImage property in the GeoJSON feature
			feature.properties.iconImage = imageId
		})

	// Remove layer if they already exists before adding new source/layers
	if (typeof map.getLayer("POIS-layer") !== "undefined") {
		map.removeLayer("POIS-layer")
	}
	if (typeof map.getSource("POIS-source") !== "undefined") {
		// map.removeSource("POIS-source")
		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("POIS-source").setData({
			type: "FeatureCollection",
			features: geoJsonArrayPOIS
		})
	}

	if (!map.getSource("POIS-source")) {
		map.addSource("POIS-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: geoJsonArrayPOIS
			}
		})
	}

	// const correspondentOfOneHundredPercent = 0.8
	const newSize = POISSize || POISSize === 0 ? pixelToSize(POISSize) : 0.4

	if (!map.getLayer("POIS-layer")) {
		map.addLayer(
			{
				id: "POIS-layer",
				type: "symbol",
				source: "POIS-source",
				// filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": ["get", "iconImage"],
					"icon-size": newSize ? newSize : 0.4,
					// "icon-size": 0.4,
					// "icon-size": 0.8,
					"symbol-sort-key": ["get", "iconImage"],
					"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
					"icon-ignore-placement": true, // Ignore placement for better clustering behavior
					visibility: showLayersBesidesTags ? "visible" : "none"
				}
			},
			"state-label"
		)
	}
}

export function addPOIMarkers(params) {
	const {
		map,
		pointsOfInterest,
		floorPlanId,
		iconsFromDB,
		setGetDirections,
		floorPlan,
		geoJsonRoutesUndefinedSnackBar,
		// setTravelTo,
		// setCopyOfStartPointCoords,
		setClickedToResetPath,
		containerRef,
		isFullscreen,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		markersSize,
		showPOIs,
		showLayersBesidesTags,
		POISSize
	} = params

	var poiMarkers = []
	const layers = map.getStyle()

	if (pointsOfInterest?.length === 0) {
		// Remove layer if they already exists before adding new source/layers
		if (layers) {
			if (typeof map.getLayer("POIS-layer") !== "undefined") {
				map.removeLayer("POIS-layer")
			}
			if (typeof map.getSource("POIS-source") !== "undefined") {
				map.removeSource("POIS-source")
			}
		}

		return poiMarkers
	}

	// filter POIs for selectedFloorplan and if showPOIs filter
	const _pointsOfInterestFiltered = !showPOIs
		? []
		: pointsOfInterest?.filter(poi => poi.floorPlanId === floorPlanId)

	_pointsOfInterestFiltered &&
		_pointsOfInterestFiltered.forEach(poi => {
			if (poi.floorPlanId !== floorPlanId) {
				return
			}

			// console.log("🚀  iconsFromDB:", iconsFromDB)

			const iconsMarkerImage = iconsFromDB && iconsFromDB.find(icon => icon.name === poi.type)

			const namesWithoutWhiteSpace =
				iconsMarkerImage && iconsMarkerImage?.name.replace(/ +/g, "")

			const el2 = document.createElement("div")

			const newPopupStick3 = iconsMarkerImage && poi && (
				<PopoverStickOnHover
					component={
						<>
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px"
								}}
							>
								<Carousel
									indicators={false}
									controls={poi.imagesUrls?.length > 1}
									interval={1500}
								>
									{poi.imagesUrls?.length > 0 &&
										poi.imagesUrls.map((arrayUrl, i) => (
											<Carousel.Item key={i} interval={1000}>
												<img
													src={arrayUrl}
													alt={poi.type}
													style={{
														borderRadius: "5px 5px 0px 0px",
														height: "90px",
														width: "276px",
														objectFit: "cover",
														objectPosition: "center",
														cursor: "pointer" // Add this to change the cursor to a pointer
													}}
												/>
											</Carousel.Item>
										))}{" "}
								</Carousel>
								<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
									<span>{poi.name}</span>

									<div>
										<IoLocationSharp
											style={{
												height: "18px",
												width: "18px",
												marginRight: "5px"
											}}
										/>
										<span
											style={{
												fontWeight: 300,
												fontSize: "10px",
												lineHeight: "160%"
											}}
										>
											<span>
												{poi?.floorPlanId === floorPlanId ? floorPlan?.name : null}
											</span>
										</span>
									</div>
								</div>
								<InfoPOIDashboard
									poi={poi}
									floorPlan={floorPlan}
									geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
									setGetDirections={setGetDirections}
									setEndingPoint={setEndingPoint}
									setClickedToResetPath={setClickedToResetPath}
									setOpenDrawerBar={setOpenDrawerBar}
									setDrawerOptions={setDrawerOptions}
								/>
								{/* <div className="popupTextDashboard">
								<div className="d-flex justify-content-between align-items-baseline mb-3">
									<h6
										style={{
											paddingBottom: "1px",
											paddingTop: "1px",
											fontSize: "14px",
											marginRight: "1rem",
											fontWeight: "700"
										}}
									>
										<b>{poi.name}</b>
									</h6>
								</div>

								<h6
									style={{
										paddingBottom: "1px",
										paddingTop: "1px",
										fontSize: "11px",
										fontWeight: "500"
									}}
								>
									Type: <b>{poi.type}</b>
								</h6>
								<h6
									style={{
										paddingBottom: "1px",
										paddingTop: "1px",
										fontSize: "11px",
										fontWeight: "500",
										marginBottom: "1rem"
									}}
								>
									Description: <b>{poi.description}</b>
								</h6>
								<Button
									variant="contained"
									style={{
										fontWeight: "500",
										backgroundColor: "#2673F0",
										color: "#f4f4f4",
										height: "32px",
										borderRadius: "2px"
									}}
									onClick={() => {
										if (
											!floorPlan?.geoJsonRoutes ||
											floorPlan?.geoJsonRoutes.features.length === 0
										) {
											geoJsonRoutesUndefinedSnackBar(floorPlan)
											return
										}
										// ↓ Select POI has start point in directions box
										setGetDirections({
											type: "poi",
											feature: poi,
											clicked: true,
											group: "Points of Interest",
											floorPlanId: poi?.floorPlanId
										})
										setEndingPoint({
											group: "Points of Interest",
											...poi
										}) // new endingPoint

										// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
										// setCopyOfStartPointCoords([
										//   poi.coords.longitude,
										//   poi.coords.latitude,
										// ]);

										// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
										// // ↓ Empty go to in directions box
										// setTravelTo();

										// ↓ Reset Path
										setClickedToResetPath(true)
										// ↓ opens New Path box on drawer
										setOpenDrawerBar(true)
										setDrawerOptions({
											openSearch: false,
											openFilter: false,
											openPath: true,
											openOccupancy: false,
											openSatellite: false
										})
									}}
								>
									<span style={{ fontSize: "10px" }}>Directions</span>
									<DirectionsIcon
										style={{
											color: "#f4f4f4",
											fontSize: "18px",
											marginLeft: "0.8rem",
											marginRight: "-0.8rem"
										}}
									/>
								</Button>
							</div> */}
							</div>
						</>
					}
					placement="top"
					delay={200}
					containerRef={isFullscreen && containerRef}
				>
					<div
						className="d-flex justify-content-center align-items-center slider-markers-size markers-pois"
						style={{
							id: `${poi.id}`,
							cursor: "pointer",
							width:
								markersSize && markersSize?.pois
									? `${Math.floor((markersSize?.pois / 100) * (80 - 14 + 1) + 14)}px`
									: "106px",
							height:
								markersSize && markersSize?.pois
									? `${Math.floor((markersSize?.pois / 100) * (80 - 14 + 1) + 14)}px`
									: "106px",
							position: "relative",
							overflow: "hidden",
							// outline: "2px solid #ffffff", // old code
							borderRadius: "50%",
							// backgroundColor: "#5491f3" // old code
							backgroundColor: "transparent"
							// border: "1px solid red"
						}}
					>
						{/* <img
						src={iconsMarkerImage?.url}
						alt={iconsMarkerImage.name}
						className="iconImageDashboard"
						id={namesWithoutWhiteSpace}
						style={{
							width: "74%",
							// width: "12px",
							height: "auto",
							display: "block",
							padding: "2px"
						}}
					/> */}
					</div>
				</PopoverStickOnHover>
			)

			// Render the marker with popup sticky
			ReactDOM.render(newPopupStick3, el2)
			// Create the marker
			const markerWithImage = new mapboxgl.Marker(el2, {
				draggable: false
			}).setLngLat([poi.coords.longitude, poi.coords.latitude])

			// Add marker to map
			markerWithImage.addTo(map)

			poiMarkers.push(el2)
		})

	// ━━━━━━━━━ Add markers with Layers
	// console.log("🎃 🎃 🎃 🎃 🎃 map.getStyle()", map.getStyle().layers)

	if (layers) {
		//  layers markers
		addPOISHasLayers({
			map,
			pointsOfInterest: _pointsOfInterestFiltered,
			showLayersBesidesTags: showLayersBesidesTags,
			POISSize
		})
	}

	return poiMarkers
}

function InfoPOIDashboard(props) {
	const {
		poi,
		floorPlan,
		geoJsonRoutesUndefinedSnackBar,
		setGetDirections,
		setEndingPoint,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions
	} = props

	return (
		<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
			<div className="flex-fill mr-3 p-0">
				<div>
					<div
						style={{
							background: "#FFFFFF",
							borderRadius: "4px",
							minHeight: "5.5rem"
						}}
					>
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "300",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							Type
						</div>
						<hr className="m-0" />
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "600",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							{poi.type}
						</div>
					</div>
					<div className="d-flex justify-content-center mt-3">
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="quick-actions-tooltip">Get directions</Tooltip>}
						>
							<Button
								variant="contained"
								style={{
									fontWeight: "500",
									backgroundColor: "#2673F0",
									color: "#f4f4f4",
									height: "24px",
									borderRadius: "4px",
									width: "100%"
								}}
								onClick={() => {
									if (
										!floorPlan?.geoJsonRoutes ||
										floorPlan?.geoJsonRoutes.features.length === 0
									) {
										geoJsonRoutesUndefinedSnackBar(floorPlan)
										return
									}
									// ↓ Select POI has start point in directions box
									setGetDirections({
										type: "poi",
										feature: poi,
										clicked: true,
										group: "Points of Interest",
										floorPlanId: poi?.floorPlanId
									})
									setEndingPoint({
										group: "Points of Interest",
										...poi
									}) // new endingPoint

									// ↓ Reset Path
									setClickedToResetPath(true)
									// ↓ opens New Path box on drawer
									setOpenDrawerBar(true)
									setDrawerOptions({
										openSearch: false,
										openFilter: false,
										openPath: true,
										openOccupancy: false,
										openSatellite: false
									})
								}}
							>
								<img alt="Directions" src="/icons/directions.svg" />
							</Button>
						</OverlayTrigger>
					</div>
				</div>
			</div>
			<div
				className="flex-fill p-0"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					overflowY: "auto", // Enable vertical scrolling
					maxHeight: "108px",
					maxWidth: "150px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Description
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{poi?.description}
				</div>
			</div>
		</div>
	)
}
