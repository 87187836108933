import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import defaultBuilding from "../../../../assets/designStudio/account_tree_white_36dp.png"
import { ImArrowUp2, ImArrowDown2 } from "react-icons/im"
import { formatString, upperCase } from "../../../Admin/designStudio/UIHelpers"
import PopoverStickOnHover from "../PopoverStickOnHover"
import { IconButton } from "@material-ui/core"
import * as turf from "@turf/turf"
import "@turf/angle"
import PathFinder from "geojson-path-finder"
import { IoLocationSharp } from "react-icons/io5"
import elevatorImg from "../../../../assets/dashboard_markers/newMarkers/connectors/Elevator.png"
import stairsImg from "../../../../assets/dashboard_markers/newMarkers/connectors/Stairs.png"
import escalatorImg from "../../../../assets/dashboard_markers/newMarkers/connectors/Escalator.png"
import rampImg from "../../../../assets/dashboard_markers/newMarkers/connectors/Ramp.png"

import { profileSlice } from "../../../../redux/profile/profileSlice"

const { actions } = profileSlice

// Draws geoJson Line nearest Path in map
export const addGeoJsonLayerToMapWithNewPath = params => {
	//━━━ Parameters ━━━\\
	const {
		map,
		pathFound,
		// travelTo, // end point
		copyOfStartPointCoords, // start point
		floorPlan,
		copyOfConnectorCoords,
		poiToDoorCoords,
		poiToDoorEndPointCoords,
		endingPoint,
		startingPoint
	} = params
	// console.log("🚀  startingPoint:", startingPoint)
	// console.log("🚀  endingPoint:", endingPoint)

	if (!map) return

	//━━━ Remove source and layer ━━━\\
	// var layerDashedLine = map.getLayer("line-dashed");
	// if (typeof layerDashedLine !== "undefined") {
	//   map.removeLayer("line-dashed");
	// }

	var layer = map.getLayer("pathLayer")
	if (typeof layer !== "undefined") {
		map.removeLayer("pathLayer")
	}
	var layerArrows = map.getLayer("routeArrows")
	if (typeof layerArrows !== "undefined") {
		map.removeLayer("routeArrows")
	}
	var layerEndPoint = map.getLayer("pathLayerEndPoint")
	if (typeof layerEndPoint !== "undefined") {
		map.removeLayer("pathLayerEndPoint")
	}
	var layerStartPoint = map.getLayer("pathLayerStartPoint")
	if (typeof layerStartPoint !== "undefined") {
		map.removeLayer("pathLayerStartPoint")
	}

	var layerStartPointFromStartToDoor = map.getLayer("pathLayerStartPointFromStartToDoor") // New
	if (typeof layerStartPointFromStartToDoor !== "undefined") {
		map.removeLayer("pathLayerStartPointFromStartToDoor")
	}
	var layerDoorPoint = map.getLayer("pathLayerDoorPoint")
	if (typeof layerDoorPoint !== "undefined") {
		map.removeLayer("pathLayerDoorPoint")
	}
	var layerDoorEndPoint = map.getLayer("pathLayerDoorEndPoint")
	if (typeof layerDoorEndPoint !== "undefined") {
		map.removeLayer("pathLayerDoorEndPoint")
	}
	// Source layers must be removed last, because the layers use it.
	var source = map.getSource("path")
	if (typeof source !== "undefined") {
		map.removeSource("path")
	}
	var sourceEndPoint = map.getSource("pathTillEndPoint")
	if (typeof sourceEndPoint !== "undefined") {
		map.removeSource("pathTillEndPoint")
	}
	var sourceStartPoint = map.getSource("pathTillStartPoint")
	if (typeof sourceStartPoint !== "undefined") {
		map.removeSource("pathTillStartPoint")
	}

	var sourceStartPointFromStartToDoor = map.getSource("pathTillStartPointFromStartToDoor") // new
	if (typeof sourceStartPointFromStartToDoor !== "undefined") {
		map.removeSource("pathTillStartPointFromStartToDoor")
	}
	var sourceDoorPoint = map.getSource("pathTillDoorPoint")
	if (typeof sourceDoorPoint !== "undefined") {
		map.removeSource("pathTillDoorPoint")
	}
	var sourceDoorEndPoint = map.getSource("pathTillDoorEndPoint")
	if (typeof sourceDoorEndPoint !== "undefined") {
		map.removeSource("pathTillDoorEndPoint")
	}

	//━━━ Add floorplan source and layer ━━━\\
	map.addSource("path", {
		type: "geojson",
		data: {
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: pathFound?.path
					}
				}
			]
		}
	})

	//━━━ Add Source of line on End Point and Start Point to complete line to the marker ━━━\\
	const copyOfPath = [...pathFound?.path]
	// console.log("🚀 ~ copyOfPath", copyOfPath);
	const lastCoord = copyOfPath[copyOfPath?.length - 1]
	// console.log("🚀 ~ lastCoord", lastCoord);

	// Ending point final coords
	let travelToCoords = []

	//━━━━━━━━━━━━━━━ "Points of Interest" ━━━━━━━━━━━━━━━\\
	if (endingPoint?.group === "Points of Interest") {
		// console.log(endingPoint, "endingPoint dentro do if das Points of Interest");

		// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
		if (endingPoint.floorPlanId === floorPlan.id) {
			// console.log("floorPlanId EQUAL POI - ending point");

			travelToCoords.push(endingPoint?.coords?.longitude, endingPoint?.coords?.latitude)
		}

		// ━━━━━━━━━━━━━━━ Endpoint not on same floorPlan
		if (endingPoint.floorPlanId !== floorPlan.id) {
			// console.log("🖌️🖌️🖌️ floorPlanId NOT EQUAL POI - DRAWING PATH!!");
			// console.log(
			//   copyOfConnectorCoords,
			//   "🖌️🖌️🖌️ copyOfConnectorCoords POI - DRAWING PATH!!"
			// );

			copyOfConnectorCoords &&
				travelToCoords.push(copyOfConnectorCoords[0], copyOfConnectorCoords[1])
		}
	}

	//━━━━━━━━━━━━━━━ "Zones / Areas" ━━━━━━━━━━━━━━━\\
	if (endingPoint?.group === "Areas") {
		if (endingPoint.floorPlanId === floorPlan.id) {
			// console.log("floorPlanId EQUAL ZONES ADD ROUTES FUNCTION _____________");
			travelToCoords.push(endingPoint?.doorMarker?.longitude, endingPoint?.doorMarker?.latitude)
		}
		if (endingPoint.floorPlanId !== floorPlan.id) {
			// console.log("floorPlanId NOT EQUAL ZONES");
			// console.log(copyOfConnectorCoords, "copyOfConnectorCoords zones");
			copyOfConnectorCoords &&
				travelToCoords.push(copyOfConnectorCoords[0], copyOfConnectorCoords[1])
		}
	}

	//━━━━━━━━━━━━━━━ "People" ━━━━━━━━━━━━━━━\\
	if (
		endingPoint?.group === "People" ||
		endingPoint?.group === "Assets" ||
		endingPoint?.group === "Unassigned"
	) {
		// console.log(travelTo, "travelTo dentro do if das Points of Interest");

		if (endingPoint.floorPlanId === floorPlan.id) {
			// console.log("floorPlanId EQUAL POI");
			travelToCoords.push(endingPoint?.gpsData?.longitude, endingPoint?.gpsData?.latitude)
		}
		if (endingPoint.floorPlanId !== floorPlan.id) {
			// console.log("floorPlanId NOT EQUAL POI");
			// console.log(copyOfConnectorCoords, "copyOfConnectorCoords POI");
			copyOfConnectorCoords &&
				travelToCoords.push(copyOfConnectorCoords[0], copyOfConnectorCoords[1])
		}
	}

	// console.log("🖌️🖌️🖌️ ~ travelToCoords", travelToCoords);
	// console.log(poiToDoorEndPointCoords, "🖌️🖌️🖌️ poiToDoorEndPointCoords");

	// ↓ If POI is inside a polygon at end Point passes coords from last coord to door, and then from door to travelToCoords
	const finalCoordsEndPoint = poiToDoorEndPointCoords
		? [poiToDoorEndPointCoords, travelToCoords]
		: [lastCoord, travelToCoords]

	// console.log("🖌️🖌️🖌️  lastCoord:", lastCoord);
	// console.log("🖌️🖌️🖌️  ~ finalCoordsEndPoint", finalCoordsEndPoint);

	if (poiToDoorEndPointCoords) {
		// ↓ If POI is inside a polygon at end Point draws another layer from POI marker to Door
		const finalCoordsDoorPoint = [lastCoord, poiToDoorEndPointCoords]

		map.addSource("pathTillDoorEndPoint", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: {},
						geometry: {
							type: "LineString",
							coordinates: finalCoordsDoorPoint
						}
					}
				]
			}
		})

		map.addLayer(
			{
				id: "pathLayerDoorEndPoint",
				type: "line",
				source: "pathTillDoorEndPoint",
				layout: {
					visibility: "visible",
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					// "line-color": "#DC4D79",
					"line-color": "#8680ff",
					// "line-color": "#ffbb00",
					"line-width": 5
				}
			},
			"state-label"
		)
	}

	map.addSource("pathTillEndPoint", {
		type: "geojson",
		data: {
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: finalCoordsEndPoint
					}
				}
			]
		}
	})

	const firstCoord = copyOfPath[0]
	// console.log("🚀 ~ firstCoord", firstCoord);
	// console.log(copyOfStartPointCoords, "🖌️🖌️🖌️ 🖌️🖌️🖌️ copyOfStartPointCoords");

	// console.log(startingPoint, "🖌️🖌️🖌️ startingPoint");

	// Get only the coords of the starting point
	const newStartingPointCoords = copyOfStartPointCoords
		? copyOfStartPointCoords
		: [startingPoint?.coords?.longitude, startingPoint?.coords?.latitude]

	const finalCoordsStartPoint = poiToDoorCoords
		? [poiToDoorCoords, firstCoord]
		: [newStartingPointCoords, firstCoord]

	// console.log(
	//   "🖌️🖌️🖌️🖌️🖌️🖌️🖌️🖌️🖌️ ~ finalCoordsStartPoint",
	//   finalCoordsStartPoint
	// );
	// console.log("🖌️🖌️🖌️  newStartingPointCoords:", newStartingPointCoords);
	// console.log(poiToDoorCoords, "🖌️🖌️🖌️   poiToDoorCoords");
	// console.log(firstCoord, "🖌️🖌️🖌️    firstCoord");

	if (poiToDoorCoords) {
		// If POI is inside a polygon draws another layer from POI marker to Door
		const finalCoordsDoorPoint = [newStartingPointCoords, poiToDoorCoords]
		// console.log("🖌️🖌️🖌️  poiToDoorCoords:", poiToDoorCoords);
		// console.log("🖌️🖌️🖌️  startingPoint:", startingPoint);
		// console.log("🖌️🖌️🖌️  finalCoordsDoorPoint:", finalCoordsDoorPoint);

		map.addSource("pathTillDoorPoint", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: {},
						geometry: {
							type: "LineString",
							coordinates: finalCoordsDoorPoint
						}
					}
				]
			}
		})

		map.addLayer(
			{
				id: "pathLayerDoorPoint",
				type: "line",
				source: "pathTillDoorPoint",
				layout: {
					visibility: "visible",
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					// "line-color": "#DC4D79",
					"line-color": "#8680ff",
					// "line-color": "#ffbb00",
					"line-width": 5
				}
			},
			"settlement-label"
		)

		// Novo to POI start point
		map.addSource("pathTillStartPointFromStartToDoor", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: {},
						geometry: {
							type: "LineString",
							coordinates: [newStartingPointCoords, poiToDoorCoords]
						}
					}
				]
			}
		})
		// Novo to POI start point
		map.addLayer(
			{
				id: "pathLayerStartPointFromStartToDoor",
				type: "line",
				source: "pathTillStartPointFromStartToDoor",
				layout: {
					visibility: "visible",
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					// "line-color": "#90e0ef",
					// "line-color": "#DC4D79",
					"line-color": "#8680ff",
					"line-width": 5
				}
			},
			"settlement-label"
		)
	}

	map.addSource("pathTillStartPoint", {
		type: "geojson",
		data: {
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: finalCoordsStartPoint
					}
				}
			]
		}
	})

	map.addLayer(
		{
			id: "pathLayerStartPoint",
			type: "line",
			source: "pathTillStartPoint",
			layout: {
				visibility: "visible",
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				// "line-color": "#00FF00",
				// "line-color": "#DC4D79",
				"line-color": "#8680ff",
				"line-width": 5
			}
		},
		"settlement-label"
	)

	map.addLayer(
		{
			id: "pathLayerEndPoint",
			type: "line",
			source: "pathTillEndPoint",
			layout: {
				visibility: "visible",
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				// "line-color": "#3a86ff",
				// "line-color": "#DC4D79",
				"line-color": "#8680ff",
				"line-width": 5
			}
		},
		"settlement-label"
	)

	// Main Path line
	map.addLayer(
		{
			id: "pathLayer",
			type: "line",
			source: "path",
			layout: {
				visibility: "visible",
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				// "line-color": "#ed2121",
				// "line-color": "#DC4D79",
				"line-color": "#8680ff",
				"line-width": 5
			}
		},
		"settlement-label"
	)

	// Arrows Layer
	map.addLayer(
		{
			id: "routeArrows",
			type: "symbol",
			source: "path",
			layout: {
				visibility: "visible",
				"symbol-placement": "line",
				"text-field": "▶",
				"text-size": ["interpolate", ["linear"], ["zoom"], 6, 10, 18, 19],
				"symbol-spacing": ["interpolate", ["linear"], ["zoom"], 12, 20, 22, 95],
				"text-keep-upright": false
			},
			paint: {
				"text-color": "hsl(55, 11%, 96%)",
				// "text-halo-color": "#DC4D79",
				"text-halo-color": "#8680ff",
				// "text-color": "#ffbb00",
				// "text-color": "#DC4D79",
				// "text-halo-color": "hsl(55, 11%, 96%)",
				"text-halo-width": 2
			}
		},
		"settlement-label"
		// 'waterway-label'
	)

	//   // Animation 1
	// add a line layer with line-dasharray set to the first value in dashArraySequence
	// map.addLayer({
	//   type: "line",
	//   source: "path",
	//   id: "line-dashed",
	//   paint: {
	//     "line-color": "#fff9e9",
	//     "line-width": 5,
	//     "line-dasharray": [0, 4, 3],
	//   },
	// });

	//   const dashArraySequence = [
	//     [0, 4, 3],
	//     [0.5, 4, 2.5],
	//     [1, 4, 2],
	//     [1.5, 4, 1.5],
	//     [2, 4, 1],
	//     [2.5, 4, 0.5],
	//     [3, 4, 0],
	//     [0, 0.5, 3, 3.5],
	//     [0, 1, 3, 3],
	//     [0, 1.5, 3, 2.5],
	//     [0, 2, 3, 2],
	//     [0, 2.5, 3, 1.5],
	//     [0, 3, 3, 1],
	//     [0, 3.5, 3, 0.5],
	//   ];

	//   let step = 50;

	//   function animateDashArray(timestamp) {
	//     // Update line-dasharray using the next value in dashArraySequence. The
	//     // divisor in the expression `timestamp / 50` controls the animation speed.

	//     const newStep = parseInt((timestamp / 50) % dashArraySequence.length);

	//     if (newStep !== step) {
	//       map.setPaintProperty(
	//         "line-dashed",
	//         "line-dasharray",
	//         dashArraySequence[step]
	//       );
	//       step = newStep;
	//     }

	//     // Request the next frame of the animation.
	//     requestAnimationFrame(animateDashArray);
	//   }

	//   // start the animation
	//   animateDashArray(0);

	// // Animation 2
	// var dashLength = 4; // size of line-dash
	// var gapLength = 4; // quantity of lines (less is more)

	// // We divide the animation up into 40 steps to make careful use of the finite space in
	// // LineAtlas
	// var steps = 40; // velocity of line-dash (more is slower)
	// // A # of steps proportional to the dashLength are devoted to manipulating the dash
	// var dashSteps = (steps * dashLength) / (gapLength + dashLength);
	// // A # of steps proportional to the gapLength are devoted to manipulating the gap
	// var gapSteps = steps - dashSteps;

	// // The current step #
	// var step = 0;

	// setInterval(function() {
	//   console.log("printing!");

	//   step = step + 1;
	//   if (step >= steps) step = 0;

	//   var t, a, b, c, d;
	//   if (step < dashSteps) {
	//     t = step / dashSteps;
	//     a = (1 - t) * dashLength;
	//     b = gapLength;
	//     c = t * dashLength;
	//     d = 0;
	//   } else {
	//     t = (step - dashSteps) / gapSteps;
	//     a = 0;
	//     b = (1 - t) * gapLength;
	//     c = dashLength;
	//     d = t * gapLength;
	//   }

	//   map.setPaintProperty("line-dashed", "line-dasharray", [d, c, b, a]);
	// }, 25);

	// console.log("🎃 🎃 🎃 🎃 🎃 map.getStyle()", map.getStyle().layers)
}

// Adds connectors to map
export function addConnectorsMarkers(params) {
	const {
		map,
		// connectorsMarkers,
		floorPlanId,
		floorPlanData,
		// allFloors,
		// geoJsonRoutes,
		connectorAccess,
		// travelTo,
		selectedFloor,
		dispatch,
		setPathFound,
		// directionsControl,
		// setGetDirections,
		setConnectorAccess,
		// setTravelTo,
		copyOfConnectorCoords,
		setCopyOfStartPointCoords,
		geoJsonRoutesUndefinedSnackBar,
		zones,
		setPoiToDoorEndPointCoords,
		setPoiToDoorCoords,
		containerRef,
		isFullscreen,
		showPathOnMap,
		startingPoint,
		endingPoint,
		showConnectors,
		showLayersBesidesTags
	} = params

	const connectorsMarkersArray = [] // ← Array with all markers
	const layers = map.getStyle()

	if (!floorPlanId || !floorPlanData) {
		if (layers) {
			if (typeof map.getLayer("connectors-layer") !== "undefined") {
				map.removeLayer("connectors-layer")
			}
			if (typeof map.getSource("connectors-source") !== "undefined") {
				map.removeSource("connectors-source")
			}
		}
		return
	}

	const travelToSelectedFloor =
		endingPoint && floorPlanData && floorPlanData.find(f => f.id === endingPoint.floorPlanId)

	// display html markers on existing path and connectorAccess.id
	const markersDisplayConditionOnPath =
		travelToSelectedFloor && connectorAccess.id !== null ? true : false

	// console.log("🎯🎯🎯🎯🎯🎯🎯  endingPoint:", endingPoint);
	// console.log(floorPlanId, "floorPlanId");
	// console.log(copyOfConnectorCoords, "copyOfConnectorCoords");

	let arrowIcon = <ImArrowUp2 style={{ color: "white" }} />
	if (travelToSelectedFloor && selectedFloor.level > travelToSelectedFloor.level) {
		arrowIcon = <ImArrowDown2 style={{ color: "white" }} />
	}

	// filter connectors markers if showConnectors filter
	const _filteredFloorPlanData = !showConnectors ? [] : floorPlanData

	// ↓ Add marker for each floorPlanData that as a linked connector
	_filteredFloorPlanData &&
		_filteredFloorPlanData.forEach(floor => {
			const selectedConnector =
				connectorAccess &&
				floor?.geoJsonRoutes &&
				floor?.geoJsonRoutes?.features.find(
					f => connectorAccess.id === f.properties.connectorType
				)

			floor.geoJsonRoutes?.features &&
				floor.geoJsonRoutes.features.forEach(f => {
					// console.log(f, "f")

					if (f.properties.linkToFloors?.includes(floorPlanId)) {
						// console.log("connector linked to this floor ->", f);

						const coords = []
						f.geometry.coordinates.forEach(coordinates => {
							coords.push(coordinates.longitude, coordinates.latitude)
						})

						let connectorTypeImage = defaultBuilding
						let idForEachType = f.properties.connectorName

						switch (f.properties.connectorType) {
							case "ELEVATOR":
								connectorTypeImage = "/icons/elevator.svg"
								idForEachType = "Elevator1"
								break
							case "STAIRS":
								connectorTypeImage = "/icons/stairs.svg"
								idForEachType = "Stairs1"
								break
							case "ESCALATOR":
								connectorTypeImage = "/icons/escalator.svg"
								idForEachType = "Escalator1"
								break
							case "RAMP":
								connectorTypeImage = "/icons/ramp.svg"
								idForEachType = "wheelChairRamp"
								break
							default:
								connectorTypeImage = defaultBuilding
						}

						const typeWithoutAllLetterUppercase = formatString(f?.properties?.connectorType)

						// Names without spaces
						const namesWithoutWhiteSpace = idForEachType.replace(/ +/g, "")

						const popupstickyConnectors = f && (
							<PopoverStickOnHover
								component={
									<div style={{ borderRadius: "8px", background: "transparent" }}>
										{connectorAccess.id === f.properties.connectorType &&
										showPathOnMap ? (
											<div
												style={{
													background: "#ECF1F4",
													boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
													borderRadius: "8px",
													fontFamily: "Poppins",
													fontStyle: "normal",
													color: "#4A4A68",
													paddingBottom: "2px",
													width: "276px"
												}}
											>
												<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
													<span>{f.properties.connectorName}</span>
													<div>
														<IoLocationSharp
															style={{
																height: "18px",
																width: "18px",
																marginRight: "5px"
															}}
														/>
														<span
															style={{
																fontWeight: 300,
																fontSize: "10px",
																lineHeight: "160%"
															}}
														>
															<span>{floor?.name}</span>
														</span>
													</div>
												</div>
												<InfoPOIDashboard_2
													feat={f}
													floorPlan={floor}
													floorPlanData={floorPlanData}
													typeWithoutAllLetterUppercase={typeWithoutAllLetterUppercase}
												/>
											</div>
										) : (
											<div
												style={{
													background: "#ECF1F4",
													boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
													borderRadius: "8px",
													fontFamily: "Poppins",
													fontStyle: "normal",
													color: "#4A4A68",
													paddingBottom: "2px",
													width: "276px"
												}}
											>
												<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
													<span>{f.properties.connectorName}</span>
													<div>
														<IoLocationSharp
															style={{
																height: "18px",
																width: "18px",
																marginRight: "5px"
															}}
														/>
														<span
															style={{
																fontWeight: 300,
																fontSize: "10px",
																lineHeight: "160%"
															}}
														>
															<span>{floor?.name}</span>
														</span>
													</div>
												</div>
												<InfoPOIDashboard
													feat={f}
													floorPlanData={floorPlanData}
													typeWithoutAllLetterUppercase={typeWithoutAllLetterUppercase}
												/>
											</div>
										)}
									</div>
								}
								placement="top"
								delay={200}
								containerRef={isFullscreen && containerRef}
								id="popoverConnectorsDash"
							>
								<div
									className="popupLineRoutes_dashboard"
									style={{
										backgroundColor:
											markersDisplayConditionOnPath && showPathOnMap
												? "#8680ff"
												: "transparent",
										border:
											markersDisplayConditionOnPath && showPathOnMap
												? "2px solid white"
												: "2px solid transparent"
									}}
									id={
										connectorAccess.id === f.properties.connectorType && showPathOnMap
											? connectorAccess.id
											: null
									}
									onClick={() => {
										// console.log("clicked connector!!!!!!!!!! ")
										// console.log(
										//   Boolean(
										//     connectorAccess.id === f.properties.connectorType
										//   ),
										//   "BOOLEAN addShowConnectors->  connectorAccess.id === f.properties.connectorType"
										// );

										// ↓ if no routes drawn display message to user
										if (
											!travelToSelectedFloor?.geoJsonRoutes ||
											travelToSelectedFloor?.geoJsonRoutes.features.length === 0
										) {
											geoJsonRoutesUndefinedSnackBar(travelToSelectedFloor)
											return
										}
										if (
											connectorAccess.id === f.properties.connectorType &&
											showPathOnMap
										) {
											// ↓ change to floor of travelTo point
											travelToSelectedFloor &&
												dispatch(actions.floorPlanSelected(travelToSelectedFloor))

											// Check if map zoom is less that 18, if it is set to great than 18 to display connectors layers markers.
											setTimeout(() => {
												const currentZoom = map.getZoom()
												if (currentZoom < 18) {
													map.setZoom(18.1)
												}
											}, [0])

											// New add path onChange function
											addPathAfterTravellingConnector({
												selectedConnector,
												// travelTo, // not needed anymore
												endingPoint,
												geoJsonRoutes: travelToSelectedFloor?.geoJsonRoutes,
												dispatch,
												setPathFound,
												map,
												// directionsControl, // not needed anymore
												// setGetDirections,
												setConnectorAccess,
												// setTravelTo, // not needed anymore
												// copyOfConnectorCoords, // not needed anymore ???
												setCopyOfStartPointCoords,
												floorPlan: travelToSelectedFloor,
												zones,
												setPoiToDoorEndPointCoords,
												setPoiToDoorCoords
											})
											document.getElementById("popoverConnectorsDash") &&
												document.getElementById("popoverConnectorsDash").remove() // ← removes popup when clicked to change floor
										}
									}}
								>
									<div style={{ margin: "3px" }}>
										<img
											src={connectorTypeImage}
											alt={f.properties.connectorName}
											className="iconImageRoutes"
											id={namesWithoutWhiteSpace}
											style={{
												// display: "none"
												display:
													// connectorAccess.id === f.properties.connectorType &&
													markersDisplayConditionOnPath && showPathOnMap
														? "block"
														: "none",
												width: markersDisplayConditionOnPath && showPathOnMap && "16px",
												height: markersDisplayConditionOnPath && showPathOnMap && "16px"
											}}
										/>
									</div>
									{connectorAccess.id === f.properties.connectorType && showPathOnMap ? (
										<div style={{ margin: "0px" }}>
											<IconButton size="small" style={{ fontSize: "14px" }}>
												{arrowIcon}
											</IconButton>
										</div>
									) : null}
									{connectorAccess.id === f.properties.connectorType && showPathOnMap ? (
										<div style={{ margin: "4px" }}>
											<h4 style={{ color: "white", margin: "0px", fontSize: "14px" }}>
												<b>{travelToSelectedFloor && travelToSelectedFloor.level}</b>
											</h4>
										</div>
									) : null}
								</div>
							</PopoverStickOnHover>
						)

						// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords com geoJsonRoutes
						const connectorPositionInSelectedFloor =
							f.properties?.connectorPositionOnFloors &&
							f.properties?.connectorPositionOnFloors.find(
								marker => marker.floorPlanId === floorPlanId
							)
						// console.log(
						//   "🚀 ~ connectorPositionInSelectedFloor",
						//   connectorPositionInSelectedFloor
						// );

						const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
							connectorPositionInSelectedFloor.coords.longitude,
							connectorPositionInSelectedFloor.coords.latitude
						]
						// console.log(
						//   "🚀 ~ connectorPositionFetchedCoords",
						//   connectorPositionFetchedCoords
						// );

						const conditionalCoords = connectorPositionFetchedCoords
							? connectorPositionFetchedCoords
							: coords
						// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

						const div = document.createElement("div")
						div.className = "connectorsMarkerDashboard"
						div.style.cursor = "pointer"
						div.style.zIndex = connectorAccess.zIndex // qnd o directions é activado o zIndex muda p cima
						ReactDOM.render(popupstickyConnectors, div)

						const markerWithPopup = new mapboxgl.Marker(div, {
							draggable: false
						})

						markerWithPopup.setLngLat(conditionalCoords).addTo(map)
						connectorsMarkersArray.push(markerWithPopup)
					}
				})
		})

	// ━━━━━━━━━ Add markers with Layers
	// console.log("🎃 🎃 🎃 🎃 🎃 map.getStyle()", map.getStyle().layers)

	// parse only geoJsonRoutes features linked to this floorPlanId
	const selectedFloorplanFeatures = _filteredFloorPlanData.flatMap(floor =>
		floor.geoJsonRoutes?.features
			? floor.geoJsonRoutes.features.filter(f =>
					f.properties.linkToFloors?.includes(floorPlanId)
			  )
			: []
	)

	if (layers) {
		//  layers markers
		addConnectorsHasLayers({
			map,
			selectedFloorplanFeatures: selectedFloorplanFeatures,
			showLayersBesidesTags: showLayersBesidesTags,
			markersDisplayConditionOnPath,
			showPathOnMap
		})
	}

	return connectorsMarkersArray
}

export function addConnectorsHasLayers(params) {
	const {
		map,
		selectedFloorplanFeatures,
		showLayersBesidesTags,
		markersDisplayConditionOnPath,
		showPathOnMap
	} = params

	if (typeof map.getLayer("connectors-layer") !== "undefined") {
		map.removeLayer("connectors-layer")
	}
	if (typeof map.getSource("connectors-source") !== "undefined") {
		map.removeSource("connectors-source")
	}

	// remove connectors markers layers if the html markers appear
	if (markersDisplayConditionOnPath && showPathOnMap) return

	const geoJsonArrayConnectors = selectedFloorplanFeatures
		? selectedFloorplanFeatures.map(d => {
				return {
					type: "Feature",
					properties: {
						...d.properties
					},
					geometry: {
						type: "Point",
						coordinates: [
							d?.geometry?.coordinates?.[0].longitude,
							d?.geometry?.coordinates?.[0].latitude,
							0.0
						]
					}
				}
		  })
		: []

	// console.log("🚀  geoJsonArrayConnectors:", geoJsonArrayConnectors)

	geoJsonArrayConnectors &&
		geoJsonArrayConnectors.forEach(feature => {
			const prop = feature.properties

			// Reset iconToMarker and iconVisible for each feature
			let iconToMarker = elevatorImg
			let imageId = "default-prop"

			// ━━━ Change POI images for each type
			if (prop?.connectorType === "ELEVATOR") {
				iconToMarker = elevatorImg
				imageId = "elevator-prop"
			} else if (prop?.connectorType === "STAIRS") {
				iconToMarker = stairsImg
				imageId = "stairs-prop"
			} else if (prop?.connectorType === "ESCALATOR") {
				iconToMarker = escalatorImg
				imageId = "escalator-prop"
			} else if (prop?.connectorType === "RAMP") {
				iconToMarker = rampImg
				imageId = "ramp-prop"
			}

			map.loadImage(iconToMarker, (error, img) => {
				// console.log("🚀  iconToMarker:", iconToMarker)
				// console.log("🚀  img:", img)
				if (error) {
					// console.log(`Error loading image ->`, error)
				}
				if (!img || error) return

				// If map already has the image we need to remove so there aren't any re-renders
				const mapHasImage = map.hasImage(imageId)
				// ↓ So don't get error of loading image with same sourceId
				if (mapHasImage) {
					map.removeImage(imageId)
				}

				// add icon image
				map.addImage(imageId, img)
			})

			// Set the iconImage property in the GeoJSON feature
			feature.properties.iconImage = imageId
		})

	if (!map.getSource("connectors-source")) {
		map.addSource("connectors-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: geoJsonArrayConnectors
			}
		})
	}

	if (!map.getLayer("connectors-layer")) {
		map.addLayer(
			{
				id: "connectors-layer",
				type: "symbol",
				source: "connectors-source",
				// filter: ["!", ["has", "point_count"]],
				layout: {
					"icon-image": ["get", "iconImage"],
					"icon-size": 0.4,
					// "icon-size": 0.8,
					"symbol-sort-key": ["get", "iconImage"],
					"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
					"icon-ignore-placement": true, // Ignore placement for better clustering behavior
					visibility: showLayersBesidesTags ? "visible" : "none"
				}
			},
			"state-label"
		)
	}
}

// After clicking on the connector, draws the path from connector to end point
function addPathAfterTravellingConnector(params) {
	const {
		selectedConnector,
		travelTo,
		endingPoint, // new endingPoint
		geoJsonRoutes,
		setPathFound,
		dispatch,
		// map,
		// directionsControl,
		// setGetDirections,
		setConnectorAccess,
		// setTravelTo,
		setCopyOfStartPointCoords,
		floorPlan,
		zones,
		setPoiToDoorEndPointCoords,
		setPoiToDoorCoords
	} = params

	// Add new Path after travelling to another level by connector
	let finalFeaturesLines = []
	geoJsonRoutes &&
		geoJsonRoutes.features.forEach((feature, index) => {
			if (feature.geometry.type === "LineString") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push([coords.longitude, coords.latitude])
				})
				finalFeaturesLines.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
		})

	// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

	const finalLayerLines = {
		type: "FeatureCollection",
		features: finalFeaturesLines
	}
	// console.log(finalLayerLines, "finalLayerLines");

	const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
		// ← turn lines into little chunks.
		units: "kilometers"
	})
	// console.log("🚀 ~ chunk", chunk)

	const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 })
	// console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor)

	const points = []

	for (const [key /*value*/] of Object.entries(pathFinderOnSameFloor._graph.vertices)) {
		// console.log("Key: ", key);
		const latlong = key.split(",")
		const lng = parseFloat(latlong[0])
		const lat = parseFloat(latlong[1])
		points.push(turf.point([lng, lat]))
	}
	// console.log("Points: ", JSON.stringify(points))

	if (selectedConnector && endingPoint) {
		// console.log("🚀 ~ endingPoint", endingPoint); // ← End point
		// console.log("🚀 ~ selectedConnector", selectedConnector);

		// ↓ End Point (point selected where the user want to go)
		let coordsEndPoint = []

		//━━━━━━━━━━━━━━━ endingPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		if (endingPoint?.group === "Points of Interest") {
			if (!floorPlan.geoJson) {
				return
			}
			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})

					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [endingPoint?.coords?.longitude, endingPoint?.coords?.latitude],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[endingPoint?.coords?.longitude, endingPoint?.coords?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(endingPoint, "endingPoint that exists POI inside polygon");
						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );
						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})

			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsEndPoint = [endingPoint.coords?.longitude, endingPoint.coords?.latitude]
			}

			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorEndPointCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsEndPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}
			// console.log("🚀  coordsEndPoint:", coordsEndPoint);
		}
		//━━━━━━━━━━━━━━━ endingPoint.group === "Zones / Areas" ━━━━━━━━━━━━━━━\\
		if (endingPoint?.group === "Areas") {
			coordsEndPoint = [endingPoint.doorMarker?.longitude, endingPoint.doorMarker?.latitude]
		}

		//━━━━━━━━━━━━━━━ endingPoint.group === "People" ━━━━━━━━━━━━━━━\\
		if (
			endingPoint?.group === "People" ||
			endingPoint?.group === "Assets" ||
			endingPoint?.group === "Unassigned"
		) {
			if (!floorPlan.geoJson) {
				return
			}
			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})

					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [endingPoint?.gpsData?.longitude, endingPoint?.gpsData?.latitude],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[endingPoint?.gpsData?.longitude, endingPoint?.gpsData?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(endingPoint, "endingPoint that exists POI inside polygon");

						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );
						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})

			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

			// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsEndPoint = [endingPoint.gpsData?.longitude, endingPoint.gpsData?.latitude]
			}

			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorEndPointCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsEndPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}
		}

		const destinationPointTurfed = turf.point(coordsEndPoint)
		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ ~ destinationPointTurfed",
		//   destinationPointTurfed
		// );

		//━━━━━━━━━━━━━━━ Start point result ━━━━━━━━━━━━━━━\\
		// ↓ Start Point (is going to be the selectedConnector)
		const coordsStartPoint = []
		selectedConnector.geometry.coordinates.forEach(c => {
			coordsStartPoint.push(c.longitude, c.latitude)
		})

		// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
		const connectorPositionInSelectedFloor =
			selectedConnector?.properties?.connectorPositionOnFloors &&
			selectedConnector?.properties?.connectorPositionOnFloors.find(
				marker => marker.floorPlanId === endingPoint.floorPlanId
			)
		// console.log(
		//   "🚀 ~ connectorPositionInSelectedFloor",
		//   connectorPositionInSelectedFloor
		// );

		const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
			connectorPositionInSelectedFloor.coords.longitude,
			connectorPositionInSelectedFloor.coords.latitude
		]
		// console.log(
		//   "🚀 ~ connectorPositionFetchedCoords",
		//   connectorPositionFetchedCoords
		// );

		const conditionalCoords = connectorPositionFetchedCoords
			? connectorPositionFetchedCoords
			: coordsStartPoint
		// console.log("🚀 ~ conditionalCoords 2", conditionalCoords);

		setPoiToDoorCoords(conditionalCoords) // this needs to be the selectedConnector coords (because the starting point is always the selectedConnector)
		setCopyOfStartPointCoords(conditionalCoords) // this needs to be the selectedConnector starting point after travelling to new floorplan

		// const startPointTurfed = turf.point(coordsStartPoint);
		const startPointTurfed = turf.point(conditionalCoords)
		// console.log("🚀 ~ startPointTurfed", startPointTurfed);

		//━━━━━━━━━━━━━━━ Set Path state result ━━━━━━━━━━━━━━━\\
		const nearestStartPoint =
			startPointTurfed && turf.nearestPoint(startPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

		const nearestEndPoint =
			destinationPointTurfed &&
			turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

		const start = nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates)
		// console.log("🚀 ~ start", start);

		const end = nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates)
		// console.log("🚀 ~ end", end);

		const path = start && end && pathFinderOnSameFloor.findPath(start, end)

		// console.log("Path: ", path);

		// ↓ Sets path on different floor
		if (path?.path) {
			// ↓ Set path to state
			setPathFound(path)

			// ↓ Closes directions box
			// path?.path &&
			//   setGetDirections({
			//     type: null,
			//     poi: null,
			//     clicked: false,
			//   });

			// ↓ Empty connector Access marker
			path?.path &&
				setConnectorAccess({
					clicked: false,
					zIndex: "0",
					id: null
				})
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: "Something went wrong with this path."
			})
		}
	}
}

// Removes Path layers of map when click on reset path button
export const removePathLayers = map => {
	if (!map) return

	if (map) {
		//━━━ Remove layer ━━━\\
		// var layerDashedLine = map.getLayer("line-dashed");
		// if (typeof layerDashedLine !== "undefined") {
		//   map.removeLayer("line-dashed");
		// }

		var layer = map.getLayer("pathLayer")
		if (typeof layer !== "undefined") {
			map.removeLayer("pathLayer")
		}
		var layerArrows = map.getLayer("routeArrows")
		if (typeof layerArrows !== "undefined") {
			map.removeLayer("routeArrows")
		}
		var layerEndPoint = map.getLayer("pathLayerEndPoint")
		if (typeof layerEndPoint !== "undefined") {
			map.removeLayer("pathLayerEndPoint")
		}
		var layerStartPoint = map.getLayer("pathLayerStartPoint")
		if (typeof layerStartPoint !== "undefined") {
			map.removeLayer("pathLayerStartPoint")
		}

		var layerStartPointFromStartToDoor = map.getLayer("pathLayerStartPointFromStartToDoor") // New
		if (typeof layerStartPointFromStartToDoor !== "undefined") {
			map.removeLayer("pathLayerStartPointFromStartToDoor")
		}
		var layerDoorPoint = map.getLayer("pathLayerDoorPoint")
		if (typeof layerDoorPoint !== "undefined") {
			map.removeLayer("pathLayerDoorPoint")
		}
		var layerDoorEndPoint = map.getLayer("pathLayerDoorEndPoint")
		if (typeof layerDoorEndPoint !== "undefined") {
			map.removeLayer("pathLayerDoorEndPoint")
		}
		// Source layers must be removed last, because the layers use it.
		var source = map.getSource("path")
		if (typeof source !== "undefined") {
			map.removeSource("path")
		}
		var sourceEndPoint = map.getSource("pathTillEndPoint")
		if (typeof sourceEndPoint !== "undefined") {
			map.removeSource("pathTillEndPoint")
		}
		var sourceStartPoint = map.getSource("pathTillStartPoint")
		if (typeof sourceStartPoint !== "undefined") {
			map.removeSource("pathTillStartPoint")
		}

		var sourceStartPointFromStartToDoor = map.getSource("pathTillStartPointFromStartToDoor") // new
		if (typeof sourceStartPointFromStartToDoor !== "undefined") {
			map.removeSource("pathTillStartPointFromStartToDoor")
		}
		var sourceDoorPoint = map.getSource("pathTillDoorPoint")
		if (typeof sourceDoorPoint !== "undefined") {
			map.removeSource("pathTillDoorPoint")
		}
		var sourceDoorEndPoint = map.getSource("pathTillDoorEndPoint")
		if (typeof sourceDoorEndPoint !== "undefined") {
			map.removeSource("pathTillDoorEndPoint")
		}
	}
}

// Draw geoJson Layers Line in map
export const addGeoJsonLayerToMapLine = params => {
	//━━━ Parameters ━━━\\
	const map = params.map
	const geoJson = params.geoJson

	//━━━ Remove source and layer ━━━\\
	var layer = map.getLayer("geoJsonLineLayerRoutes")
	if (typeof layer !== "undefined") {
		map.removeLayer("geoJsonLineLayerRoutes")
	}
	var source = map.getSource("geoJsonLineSourceRoutes")
	if (typeof source !== "undefined") {
		map.removeSource("geoJsonLineSourceRoutes")
	}
	// console.log(map.getStyle().layers, "LAYERS");

	//━━━ Add floorplan source and layer ━━━\\
	var lineGeoJson = JSON.parse(JSON.stringify(geoJson))

	geoJson.features.forEach((feature, index) => {
		typeof map.getLayer(`geoJsonLineLayerRoutes-${index}`) !== "undefined" &&
			map.removeLayer(`geoJsonLineLayerRoutes-${index}`)
		typeof map.getSource(`geoJsonLineSourceRoutes-${index}`) !== "undefined" &&
			map.removeSource(`geoJsonLineSourceRoutes-${index}`)

		var coordinates = []
		feature.geometry.coordinates.forEach(coords => {
			coordinates.push([coords.longitude, coords.latitude])
		})

		const data = {
			type: geoJson.features[index].type,
			properties: {
				...geoJson.features[index].properties,
				// color:
				//   /* feature.properties.type === "baseLayer" ?  */ currentType?.color /* : color */,
				// outline: currentType ? "#969696" : "#969696",
				// lineWidth:
				//   feature.properties.type != "baseLayer" ? (currentType ? 2 : 2) : 4,
				color: "#3bb3d0",
				outline: "#3bb3d0",
				opacity: 0.9,
				lineWidth: 3
			},
			geometry: {
				type: geoJson.features[index].geometry.type,
				coordinates: coordinates
			}
		}

		//━━━ Add lines ━━━\\
		map.addSource(`geoJsonLineSourceRoutes-${index}`, {
			type: "geojson",
			data: data
		})
		map.addLayer(
			{
				id: `geoJsonLineLayerRoutes-${index}`,
				source: `geoJsonLineSourceRoutes-${index}`,
				type: "line",
				layout: {
					visibility: "visible"
				},
				paint: {
					"line-color": ["get", "outline"],
					"line-width": ["get", "lineWidth"]
				}
			},
			"country-label"
		)
	})

	// console.log("🚀 ~ lineGeoJson", lineGeoJson);

	//━━━ Add lines source and layer ━━━\\
	map.addSource("geoJsonLineSourceRoutes", {
		type: "geojson",
		data: lineGeoJson
	})
	map.addLayer(
		{
			id: "geoJsonLineLayerRoutes",
			source: "geoJsonLineSourceRoutes",
			type: "line",
			layout: {
				visibility: "visible",
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-color": "#ffbb00",
				"line-width": 5
			}
		},
		// "gl-draw-polygon-fill-inactive.cold"
		"state-label"
		// "country-label"
	)
}

function InfoPOIDashboard(props) {
	const { feat, floorPlanData, typeWithoutAllLetterUppercase } = props

	return (
		<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
			<div
				className="flex-fill mr-3"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					maxWidth: "150px", // Adjust the max-width as needed
					overflow: "hidden"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Type
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{typeWithoutAllLetterUppercase}
				</div>
			</div>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Linked to floors:
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "left",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68",
						maxWidth: "180px", // Adjust the max-width as needed
						overflow: "hidden",
						overflowY: "auto", // Enable vertical scrolling
						maxHeight: "108px"
					}}
				>
					{feat.properties.linkToFloors &&
						feat.properties.linkToFloors.map(res => {
							const floorName = floorPlanData && floorPlanData.find(f => f.id === res)

							const linkToFloorsCapitalized = floorName && upperCase(floorName?.name)
							if (floorName) {
								return (
									<div key={res} style={{}}>
										<ul key={res} className="m-0">
											<li>{linkToFloorsCapitalized}</li>
										</ul>
									</div>
								)
							}
							return null
						})}
				</div>
			</div>
		</div>
	)
}
function InfoPOIDashboard_2(props) {
	const { feat, floorPlanData, typeWithoutAllLetterUppercase } = props

	return (
		<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
			<div
				className="flex-fill mr-2"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					maxWidth: "150px", // Adjust the max-width as needed
					overflow: "hidden",
					minWidth: "100px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Type
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{typeWithoutAllLetterUppercase}
				</div>
			</div>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Info
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68",
						maxWidth: "180px", // Adjust the max-width as needed
						overflow: "hidden",
						overflowY: "auto", // Enable vertical scrolling
						maxHeight: "108px"
					}}
				>
					<span>
						Click on&nbsp;
						<b>{feat.properties.connectorName} marker</b> to change floor and follow path.
					</span>
				</div>
			</div>
		</div>
	)
}
