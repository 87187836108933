import React, { useState } from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { requestPassword } from "../_redux/authCrud"
import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { Button } from "../../../_partials/Button"
import "../auth.css"

const initialValues = {
	email: ""
}

function ForgotPassword(props) {
	const { intl } = props

	const [isLoading, setIsLoading] = useState(false)
	const [isRequested, setIsRequested] = useState(false)
	const [snackBarOpen, setSnackBarOpen] = useState(false)
	const [allowRequest, setAllowRequest] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	const ForgotPasswordSchema = Yup.object().shape({
		email: Yup.string()
			.email("Wrong email format")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				})
			)
	})

	const getInputClasses = fieldname => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid"
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid"
		}

		return ""
	}

	const formik = useFormik({
		initialValues,
		validationSchema: ForgotPasswordSchema,
		onSubmit: values => {
			setIsLoading(true)
			requestPassword(values.email)
				.then(response => response.json())
				.then(() => {
					setSnackBarOpen(true)
					setIsRequested(true)
					setAllowRequest(false)
					setTimeout(() => setAllowRequest(true), 60000)
					setIsLoading(false)
				})
				.catch(() => {
					/* console.log(err)
					setIsRequested(false)
					setSubmitting(false)
					setIsLoading(false)
					setStatus("Unable to reset password due to an error. Please try again.") */
				})
		}
	})

	function handleClose() {
		setSnackBarOpen(false)
	}

	const sendAgainStyle = {
		width: "-webkit-fill-available",
		marginBottom: "0.5rem",
		/* backgroundColor: isHovered ? "#2673F0" : "#2673F0", */
		color: isHovered ? "#fff" : "#fff"
	}

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				open={snackBarOpen}
				autoHideDuration={5000}
				onClose={handleClose}
			>
				<MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
					Reset password email sent!
				</MuiAlert>
			</Snackbar>

			{/* <Redirect to="/auth" /> */}
			{isRequested && (
				<div
					className="login-form login-forgot"
					style={{
						display: "block",
						backgroundColor: "#FFF",
						filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
						borderRadius: "5px"
						/* padding: "4rem" */
					}}
				>
					<div className="text-center mb-10 mb-lg-20" style={{ marginTop: "3rem" }}>
						<img src="../logos/ck-icon.svg" style={{ marginBottom: "0.5rem" }} />
						<h1 className="font-size-h1" style={{ color: "#0A1B3D", marginBottom: "0.5rem" }}>
							CROWDKEEP
						</h1>
						<h5 className="font-size-h5" style={{ color: "#0A1B3D", marginBottom: "rem" }}>
							Construction
						</h5>
						<h3
							className="font-size-h2"
							style={{ color: "#4A4A68", marginBottom: "0 !important" }}
						>
							Check your e-mail
						</h3>
						<div className="text-muted font-weight-bold mt-4">
							We have sent the password recover <br /> instructions to your e-mail
						</div>
					</div>
					<form className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
						{/* <div className="form-group d-flex flex-wrap flex-center"> */}
						<div
							className="form-group fv-plugins-icon-container"
							style={{
								margin: "0px 2rem 3rem",
								display: "flex",
								flexFlow: "column",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<div
								className="col-lg-12 mt-2 justify-content-center"
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
							>
								<Button
									component={Link}
									to="/auth"
									id="kt_login_forgot_submit"
									type="submit"
									variant="contained"
									color="primary"
									className="w-100"
									style={sendAgainStyle}
								>
									<span>BACK TO LOGIN</span>
								</Button>
							</div>
							<div className="text-center mb-2">
								<div className="text-muted mt-4" style={{ fontSize: "x-small" }}>
									Did not receive the e-mail? Check spam folder or
								</div>
							</div>
							<div className="col-lg-8" style={{ width: "10rem", height: "4rem" }}>
								<Button
									onClick={formik.handleSubmit}
									color={allowRequest ? "primary" : ""}
									id="kt_login_forgot_cancel"
									disabled={!allowRequest}
									/* text="SEND AGAIN" */
									className="w-100"
									style={{ height: "3rem", border: "2px solid" }}
								>
									<span>SEND AGAIN</span>
									{isLoading && <span className="ml-3 spinner-border text-primary"></span>}
								</Button>
							</div>
							{!allowRequest && (
								<div className="text-muted font-weight-bold mt-4">
									<img
										src="../icons/info-recoverpassword.svg"
										style={{ marginRight: "0.5rem" }}
									/>
									<span style={{ color: "#4A4A68" }}>
										You may do another request in <strong>1 minute...</strong>
									</span>
								</div>
							)}
						</div>
					</form>
				</div>
			)}
			{!isRequested && (
				<div
					className="login-form login-forgot"
					style={{
						display: "block",
						backgroundColor: "#FFF",
						filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
						borderRadius: "5px"
						/* padding: "4rem" */
					}}
				>
					<div className="text-center" style={{ marginTop: "3rem", marginBottom: "3rem" }}>
						<img src="../logos/ck-icon.svg" style={{ marginBottom: "0.5rem" }} />
						<h1 className="font-size-h1" style={{ color: "#0A1B3D", marginBottom: "1rem" }}>
							CROWDKEEP
						</h1>
						<h5 className="font-size-h5" style={{ color: "#0A1B3D", marginBottom: "3rem" }}>
							Construction
						</h5>
						<h3
							className="font-size-h2"
							style={{ color: "#4A4A68", marginBottom: "0 !important" }}
						>
							Forgot Password?
						</h3>
						<div className="text-muted font-weight-bold mt-4">
							Enter your e-mail below to receive your <br /> password reset instructions
						</div>
					</div>
					<form
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
						style={{ margin: "0px 3rem 5rem" }}
					>
						{formik.status && (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">{formik.status}</div>
							</div>
						)}
						<div className="form-group fv-plugins-icon-container">
							<input
								id="emailField"
								name="email"
								type="email"
								className={`form-control form-control-solid ${getInputClasses("email")}`}
								placeholder="Enter e-mail"
								{...formik.getFieldProps("email")}
								style={{ backgroundColor: "#F2F2F2", padding: "25px 15px" }}
							/>
							{formik.touched.email && formik.errors.email ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">{formik.errors.email}</div>
								</div>
							) : null}
						</div>
						{/* <div className="form-group d-flex flex-wrap flex-center"> */}
						<div className="form-group row">
							<div className="col-lg-12 mt-2 justify-content-center">
								<Button
									id="kt_login_forgot_submit"
									type="submit"
									disabled={formik.isSubmitting}
									variant="contained"
									color="primary"
									className="w-100"
									style={{ width: "-webkit-fill-available", marginBottom: "0.5rem" }}
								>
									<span>RECOVER PASSWORD</span>
									{isLoading && <span className="ml-3 spinner spinner-white"></span>}
								</Button>
							</div>
							<div className="col-lg-12">
								<Button
									component={Link}
									to="/auth"
									color="primary"
									id="kt_login_forgot_cancel"
									text="Cancel"
									className="w-100"
									style={{ border: "2px solid" }}
								/>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	)
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
