import * as requestFromServer from "./liveDataCrud"
import { liveDataSlice, callTypes } from "./liveDataSlice"
import {
	convertCollectionSnapshotToMap,
	convertCollectionSnapshotToMap2
} from "../_helpers/ActionHelpers"

const { actions } = liveDataSlice

export const fetchIconsFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getIconsFromDB(queryParams)
		.then(response => {
			const iconsData = response.docs.map(doc => doc.data())
			dispatch(actions.iconsFetched(iconsData))
		})
		.catch(error => {
			console.log("Fetch icons: ", error)
			error.clientMessage = "Can't fetch icons"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAllCustomerNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getAllCustomerNodes({ ...queryParams })
		.then(response => {
			return response.json()
		})
		.then(result => {
			if (result) {
				dispatch(
					actions.customerNodesFetched({
						...result
					})
				)
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: `Error fetching customer nodes`
				})
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error fetching customer nodes"
			})
			console.log("Fetching customer nodes error: ", error)
			error.clientMessage = "Can't fetch customer nodes"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchThings = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getThings(queryParams)
		.then(response => {
			const things = response.docs.map(doc => {
				return { id: doc.id, ...doc.data() }
			})
			dispatch(actions.thingsFetched(things))
		})
		.catch(error => {
			console.log("Fetch things: ", error)
			error.clientMessage = "Can't fetch things"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchUsers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getUsers(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Error getting buddies")
			}

			dispatch(actions.usersFetched(response))
		})
		.catch(error => {
			console.log("Fetch users/buddies: ", error)
			error.clientMessage = "Can't fetch users/buddies"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchPeopleStats = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.peopleStatsFetched({ entities: [] }))
	}

	return requestFromServer
		.getPeopleStats(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			dispatch(actions.peopleStatsFetched({ entities: response.data() }))
		})
		.catch(error => {
			console.log("Fetch people stats: ", error)
			error.clientMessage = "Can't fetch people stats"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchDailyUsers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.dailyUsersFetched({ entities: [] }))
	}

	return requestFromServer
		.getDailyUsers(queryParams)
		.then(response => response.text())
		.then(result => {
			if (!result) {
				throw Error("Can't make a request for server")
			}

			var xAxis = []
			var yAxis = []

			JSON.parse(result).forEach(value => {
				xAxis.push(value[0])
				yAxis.push(value[1])
			})

			dispatch(actions.dailyUsersFetched({ entities: { xAxis, yAxis } }))
		})
		.catch(error => {
			console.log("Fetch daily users: ", error)
			error.clientMessage = "Can't fetch daily users"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchPois = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.poisFetched({ entities: [] }))
	}

	return requestFromServer
		.getPois(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const pois = convertCollectionSnapshotToMap(response)

			dispatch(actions.poisFetched(pois))
		})
		.catch(error => {
			console.log("Fetch pois: ", error)
			error.clientMessage = "Can't fetch pois"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAreasInDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.areasFetched({ entities: [] }))
	}

	return requestFromServer
		.getAreasInDB(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const areas = convertCollectionSnapshotToMap(response)

			dispatch(actions.areasFetched(areas))
		})
		.catch(error => {
			console.log("Fetch areas: ", error)
			error.clientMessage = "Can't fetch areas"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchUserPreferencesInDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.userPrefsDrawer([]))
	}

	return requestFromServer
		.getPreferencesInDB(queryParams)
		.then(response => {
			if (!response || !response.data()) {
				// throw Error("Can't make a request for server or data is undefined")
				console.log("Fetch user drawer preferences. No user preferences data returned")
				return
			}

			const prefs = response.data()

			// Check if prefs and prefs.preferences are defined before accessing
			if (prefs && prefs.preferences) {
				const parsedPreferences = [
					{
						preferences: prefs.preferences,
						userId: prefs.userId
					}
				]

				dispatch(actions.userPrefsDrawer(parsedPreferences))
			} else {
				console.log("Preferences not available")
			}
		})
		.catch(error => {
			console.log("Fetch user drawer preferences.: ", error)
			error.clientMessage = "Can't fetch user drawer preferences."
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// export const fetchZonesInDB = queryParams => dispatch => {
// 	dispatch(actions.startCall({ callType: callTypes.list }))

// 	if (!queryParams) {
// 		dispatch(actions.zonesFetched({ entities: [] }))
// 	}

// 	return requestFromServer
// 		.getZonesInDB(queryParams)
// 		.then(response => {
// 			if (!response) {
// 				throw Error("Can't make a request for server")
// 			}

// 			const zones = convertCollectionSnapshotToMap(response)

// 			dispatch(actions.zonesFetched(zones))
// 		})
// 		.catch(error => {
// 			console.log("Fetch zones: ", error)
// 			error.clientMessage = "Can't fetch zones"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 		})
// }

export const fetchResourcesMeetings = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.resourcesMeetingsFetched({ entities: [] }))
	}

	return requestFromServer
		.getResourcesMeetings(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const resources = convertCollectionSnapshotToMap(response)

			dispatch(actions.resourcesMeetingsFetched(resources))
		})
		.catch(error => {
			console.log("fetch resources meetings", error)
			error.clientMessage = "Can't find resources meetings"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAllLocationsPeopleStats = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.allLocationsPeopleStatsFetched({ entities: [] }))
	}

	return requestFromServer
		.getAllLocationsPeopleStats(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}
			const allLocationStats = convertCollectionSnapshotToMap2(response)

			// Parse and sum data from all the locations
			let obj = {}
			allLocationStats &&
				allLocationStats.forEach(item => {
					if (obj[item]) {
						obj[item].current_employees = obj[item].current_employees + item.current_employees
						obj[item].current_visitors = obj[item].current_visitors + item.current_visitors
						obj[item].current_guests = obj[item].current_guests + item.current_guests
						obj[item].employeesDay = obj[item].employeesDay + item.employeesDay
						obj[item].employeesMonth = obj[item].employeesMonth + item.employeesMonth
						obj[item].visitorsDay = obj[item].visitorsDay + item.visitorsDay
						obj[item].visitorsMonth = obj[item].visitorsMonth + item.visitorsMonth
					} else {
						obj[item] = item
					}
				})

			const allLocationStatsSummed = Object.values(obj)
			// console.log(allLocationStatsSummed, "allLocationStatsSummed");

			dispatch(
				actions.allLocationsPeopleStatsFetched({
					allLocationStats: allLocationStats,
					allLocationStatsSummed: allLocationStatsSummed[0]
				})
			)
		})
		.catch(error => {
			console.log("Fetch all locations people stats: ", error)
			error.clientMessage = "Can't fetch all locations people stats"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAllLocationsMerakiDevices = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.allLocationsMerakiDevicesFetched({ entities: [] }))
	}

	return requestFromServer
		.getAllLocationsMerakiDevices(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const allLocationMerakiDevices = convertCollectionSnapshotToMap2(response)

			dispatch(actions.allLocationsMerakiDevicesFetched(allLocationMerakiDevices))
		})
		.catch(error => {
			console.log("Fetch all locations meraki devices: ", error)
			error.clientMessage = "Can't fetch all locations meraki devices"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveUserDrawerPreferences = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.saveUserFilterPreferences(queryParams)
		.then(res => {
			if (!queryParams) {
				dispatch(actions.userPrefsDrawer([]))
			} else {
				dispatch(actions.userPrefsDrawer(queryParams))
			}
		})
		.catch(error => {
			console.log("Can't save user preferences of drawer filter: ", error)
			error.clientMessage = "Can't save user preferences of drawer filter."
		})
}

export const mapStylePreferences = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.userPrefsMapView([]))
	} else {
		dispatch(actions.userPrefsMapView(queryParams))
		// dispatch({
		// 	type: "SNACKBAR_SUCCESS",
		// 	payload: `Map style preferences saved successfully.`
		// })
	}
}

export const createNodesInNodesCollection = queryParams => {
	return requestFromServer
		.createNodes(queryParams)
		.then(response => {
			// console.log("🚀  response:", response)
		})
		.catch(error => {
			console.log("Can't create nodes: ", error)
			error.clientMessage = "Can't create nodes"
		})
}
