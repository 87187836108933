import React from "react"
import { Card, CardBody } from "../../../../_partials/Card"
import { Button } from "../../../../_partials/Button"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/data-validation/dataValidationActions"
import SVG from "react-inlinesvg"

const TermsAndConditions = ({ validationCode }) => {
	const dispatch = useDispatch()

	const { isLoading } = useSelector(
		state => ({
			isLoading: state.dataValidation?.actionsLoading
		}),
		shallowEqual
	)

	return (
		<div
			style={{
				maxWidth: "512px"
			}}
		>
			<Card isLoading={isLoading} data-testid="data-validation_TermsAndConditions">
				<CardBody padding="20px">
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "25px"
						}}
					>
						<SVG src="/logos/ck-icon.svg" style={{ marginBottom: "0.5rem" }} />
						<h1 style={{ color: "#0A1B3D" }}>CROWDKEEP</h1>
						<h5 className="font-size-h5" style={{ color: "#0A1B3D", marginBottom: "1rem" }}>
							Construction
						</h5>
					</div>
					<div
						style={{
							color: "#4A4A68",
							textAlign: "center",
							fontFamily: "Poppins",
							fontSize: "20px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "32px",
							marginBottom: "15px"
						}}
					>
						Do you accept the terms and conditions?
					</div>
					<div
						style={{
							color: "#8C8CA1",
							textAlign: "justify",
							fontFamily: "Poppins",
							fontSize: "20px",
							fontStyle: "normal",
							fontWeight: 400,
							lineHeight: "32px"
						}}
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					</div>
					<div className="mt-8">
						<Button
							disabled={isLoading}
							fullWidth
							className="mb-2"
							color="primary"
							variant="contained"
							text="YES, I ACCEPT"
							onClick={() => {
								dispatch(actions.handleTermsAcception(validationCode))
							}}
							data-testid="ACCEPT"
						/>
						<Button
							disabled={isLoading}
							fullWidth
							color="primary"
							variant="outlined"
							text="NO, I DON'T ACCEPT"
							onClick={() => {
								dispatch(actions.handleTermsRefusal(validationCode))
							}}
							data-testid="DECLINE"
						/>
					</div>
				</CardBody>
			</Card>
		</div>
	)
}

export default TermsAndConditions
