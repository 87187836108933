import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Formik, Form, Field } from "formik"
import { Input } from "../../../_partials/inputs/Input"
import * as Yup from "yup"
import { connect } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux"
import { login } from "../_redux/authCrud"
import { Snackbar } from "@material-ui/core/"
import MuiAlert from "@material-ui/lab/Alert"
import { Button } from "../../../_partials/Button"
import "../auth.css"

const EditSchema = Yup.object().shape({
	email: Yup.string().email("Wrong email format").required("Email is required"),
	password: Yup.string().required("Password is required")
})

const initialValues = {
	email: "",
	password: ""
}

function Login(props) {
	const [loading, setLoading] = useState(false)
	// const [showPass, setShowPass] = useState(false)
	const [snackBarOpen, setSnackBarOpen] = useState(false)
	const [valuesForCustomerSelection, setValuesForCustomerSelection] = useState()
	const [customerSelection, setCustomerSelection] = useState()
	const [passwordIsVisible, setPasswordIsVisible] = useState(false)

	function signIn(values) {
		setValuesForCustomerSelection(values)
		setLoading(true)

		login(values.email, values.password, values.tenantID)
			.then(response => {
				setLoading(false)
				if (response.multipleTenants) {
					const customers = response.customers.map(val => {
						if (val.tenantID) return val
						return { ...val, tenantID: "superAdmin" }
					})
					setCustomerSelection(customers)
					return
				}
				props.login(response.user.uid)
			})
			.catch(error => {
				setSnackBarOpen(error || "An error occurred. Please try again.")
				setLoading(false)
			})
	}

	function handleClose() {
		setSnackBarOpen(false)
	}

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				open={snackBarOpen}
				autoHideDuration={5000}
				onClose={handleClose}
			>
				<MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
					{snackBarOpen}
				</MuiAlert>
			</Snackbar>
			<div
				className="login-form login-signin"
				id="kt_login_signin_form"
				style={{
					backgroundColor: "#FFF",
					filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
					borderRadius: "5px"
				}}
			>
				<div className="text-center" style={{ marginTop: "3rem", marginBottom: "1.5rem" }}>
					<img src="../logos/ck-icon.svg" style={{ marginBottom: "0.5rem" }} />
					<h1 className="font-size-h1" style={{ color: "#0A1B3D", marginBottom: "0.5rem" }}>
						CROWDKEEP
					</h1>
					<h5 className="font-size-h5" style={{ color: "#0A1B3D", marginBottom: "2.5rem" }}>
						Construction
					</h5>
					<h3
						className="font-size-h2"
						style={{ color: "#4A4A68", marginBottom: "0 !important" }}
					>
						<FormattedMessage id="AUTH.LOGIN.TITLE" />
					</h3>
				</div>
				{customerSelection ? (
					<CustomerSelection
						customers={customerSelection}
						signIn={signIn}
						email={valuesForCustomerSelection?.email}
						password={valuesForCustomerSelection?.password}
						setCustomerSelection={setCustomerSelection}
					/>
				) : (
					<Formik
						enableReinitialize={true}
						validationSchema={EditSchema}
						initialValues={initialValues}
						onSubmit={values => {
							signIn(values)
						}}
					>
						{() => (
							<>
								<Form
									className="form form-label-right"
									style={{ margin: "0 3rem 5rem 3rem" }}
								>
									<div className="form-group row">
										<div className="col-lg-12">
											<Field
												id="login-input-padding"
												className="login-input-padding"
												name="email"
												component={Input}
												placeholder="Enter e-mail"
												style={{
													backgroundColor: "#F2F2F2",
													borderRadius: "4px"
												}}
											/>
										</div>
									</div>
									<div className="form-group row">
										<div
											className="col-lg-12"
											style={{ position: "relative", display: "inline-block" }}
										>
											<Field
												id="passwordField"
												className="password-field"
												name="password"
												component={Input}
												placeholder="Enter password"
												type="password"
												style={{ backgroundColor: "#F2F2F2", flex: "1" }}
											></Field>
											{!passwordIsVisible && (
												<Button
													id="togglePasswordButton"
													onClick={() => {
														setPasswordIsVisible(true)
														const passwordField =
															document.getElementById("passwordField")
														passwordField.type = "text"
													}}
													style={{
														position: "absolute",
														top: "1.8rem",
														right: "1rem",
														background: "transparent",
														border: "none",
														transform: "translateY(-50%)"
													}}
												>
													<img
														src="../icons/passwordinvisible.svg"
														style={{
															filter:
																"invert(86%) sepia(0%) saturate(0%) hue-rotate(358deg) brightness(88%) contrast(93%)",
															width: "1.5rem",
															paddingTop: "10px"
														}}
													/>
												</Button>
											)}
											{passwordIsVisible && (
												<Button
													id="togglePasswordButton"
													onClick={() => {
														setPasswordIsVisible(false)
														const passwordField =
															document.getElementById("passwordField")
														passwordField.type = "password"
													}}
													style={{
														position: "absolute",
														top: "1.8rem",
														right: "1rem",
														background: "transparent",
														border: "none",
														transform: "translateY(-50%)"
													}}
												>
													<img
														src="../icons/passwordvisible.svg"
														style={{
															filter:
																"invert(86%) sepia(0%) saturate(0%) hue-rotate(358deg) brightness(88%) contrast(93%)",
															width: "1.5rem"
														}}
													/>
												</Button>
											)}
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-12">
											<Button
												id="kt_login_signin_submit_button"
												variant="contained"
												color="primary"
												type="submit"
												style={{ width: "-webkit-fill-available" }}
											>
												<span>LOGIN</span>
												{loading && (
													<span className="ml-3 spinner spinner-white"></span>
												)}
											</Button>
										</div>
										<div className="col-lg-12 mt-2 justify-content-center">
											<Button
												component={Link}
												to="/auth/forgot-password"
												color="primary"
												id="kt_login_forgot"
												text={<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />}
												style={{ width: "-webkit-fill-available", border: "2px solid" }}
											/>
										</div>
									</div>
								</Form>
							</>
						)}
					</Formik>
				)}
			</div>
		</>
	)
}

export default injectIntl(connect(null, auth.actions)(Login))

function CustomerSelection(props) {
	const { customers, signIn, email, password, setCustomerSelection } = props

	const [state, setState] = useState()

	const handleChange = value => {
		setState(value || "superAdmin")
	}

	return (
		<>
			<div className="auth-customer" style={{ margin: "0 2.5rem 0" }}>
				{customers.map((val, i) => {
					return (
						<div
							key={i}
							className="auth-customer-container"
							onClick={() => handleChange(val.tenantID)}
						>
							<span>{val.customerName}</span>
							<div className={state === val.tenantID ? "active" : "inactive"} />
						</div>
					)
				})}
			</div>
			<div className="form-group row" style={{ margin: "0 1.5rem 3rem" }}>
				<div className="col-lg-12">
					<Button
						disabled={!state}
						color="primary"
						variant="contained"
						className="w-100"
						onClick={() => state && signIn({ email, password, tenantID: state })}
						text="CONTINUE"
						style={{ width: "-webkit-fill-available" }}
					/>
				</div>
				<div className="col-lg-12">
					<Button
						color="grey"
						variant="contained"
						className="w-100 mt-4"
						onClick={() => setCustomerSelection()}
						text="CANCEL"
						style={{
							width: "-webkit-fill-available",
							border: "2px solid #2673F0",
							color: "#2673F0",
							backgroundColor: "#FFF"
						}}
					/>
				</div>
			</div>
		</>
	)
}

// async function signInSAML(values) {
// 	setLoading(true)
// 	// var domain = values.email.substring(values.email.lastIndexOf("@") + 1)
// 	// var snapshot = await firebase
// 	//   .firestore()
// 	//   .collection("LoginData")
// 	//   .where("domain", "==", domain)
// 	//   .get()
// 	// var customerData = snapshot.docs[0]?.data()

// 	if (false) {
// 		//customerData?.services?.SAML) {
// 		// firebase.auth().tenantId = customerData.tenantID
// 		// const provider = new firebase.auth.SAMLAuthProvider(
// 		//   customerData.SAML.ProviderID
// 		// )
// 		// var result = await firebase
// 		//   .auth()
// 		//   .signInWithPopup(provider)
// 		//   .catch(error => console.log("Error: ", error))
// 		// if (result.additionalUserInfo.isNewUser) {
// 		//   await firebase
// 		//     .auth()
// 		//     .currentUser.updateProfile({
// 		//       displayName: result.additionalUserInfo.profile?.displayName,
// 		//       phoneNumber: result.additionalUserInfo.profile?.phoneNumber,
// 		//     })
// 		//     .catch(error => console.log("Error: ", error))
// 		//   const customerClaims = {
// 		//     isUser: true,
// 		//     customerId: customerData.customerId,
// 		//   }
// 		//   firebase
// 		//     .firestore()
// 		//     .doc(`user-claims/${result.user.uid}`)
// 		//     .set(customerClaims)
// 		//     .then(() => {
// 		//       props.login(result.user.uid)
// 		//       setLoading(false)
// 		//     })
// 		// } else {
// 		//   setLoading(false)
// 		//   props.login(result.user.uid)
// 		// }
// 	} else {
// 		setLoading(false)
// 		setShowPass(true)
// 	}
// }
